/* eslint-disable jsx-a11y/anchor-is-valid */
import { Sidebar as FlowbitSidebar, Tooltip } from "flowbite-react";
import type { FC } from "react";
import { useEffect, useState } from "react";
import { HiUser, HiUserGroup } from "react-icons/hi";

const Sidebar: FC = () => {
    const [currentPage, setCurrentPage] = useState("");
    const [isEcommerceOpen, setEcommerceOpen] = useState(true);

    useEffect(() => {
        const newPage = window.location.pathname;
        setCurrentPage(newPage);
        setEcommerceOpen(newPage.includes("/e-commerce/"));
    }, [setCurrentPage, setEcommerceOpen]);

    return (
        <div className="h-full " style={{ backgroundColor: "white" }}>
            <FlowbitSidebar
                aria-label="Sidebar"
                style={{ backgroundColor: "white" }}>
                <div className="flex h-full flex-col justify-between py-2">
                    <FlowbitSidebar.Items>
                        <FlowbitSidebar.ItemGroup>
                            <FlowbitSidebar.Item
                                href="#"
                                icon={HiUser}
                                className={
                                    "/admin/users" === currentPage &&
                                    "" === window.location.search
                                        ? "bg-primary-100 shadow"
                                        : ""
                                }>
                                Users
                            </FlowbitSidebar.Item>
                            <FlowbitSidebar.Item
                                href="#"
                                icon={HiUserGroup}
                                className={
                                    "/admin/groups" === currentPage &&
                                    "" === window.location.search
                                        ? "bg-primary-100 shadow"
                                        : ""
                                }>
                                <Tooltip content="Coming soon">
                                    <span className="cursor-not-allowed opacity-50">
                                        Groups
                                    </span>
                                </Tooltip>
                            </FlowbitSidebar.Item>
                            {/* <FlowbitSidebar.Collapse
                icon={HiUser}
                label="Users"
                open={isEcommerceOpen}
              >
                <FlowbitSidebar.Item
                  href="/e-commerce/products"
                  className={
                    "/e-commerce/products" === currentPage ? "bg-gray-100" : ""
                  }
                >
                  Products
                </FlowbitSidebar.Item>
                <FlowbitSidebar.Item
                  href="/e-commerce/billing"
                  className={
                    "/e-commerce/billing" === currentPage ? "bg-gray-100" : ""
                  }
                >
                  Billing
                </FlowbitSidebar.Item>
              </FlowbitSidebar.Collapse> */}
                        </FlowbitSidebar.ItemGroup>
                    </FlowbitSidebar.Items>
                </div>
            </FlowbitSidebar>
        </div>
    );
};

export default Sidebar;





