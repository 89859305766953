"use client";
import { useNavigate, useLocation } from "react-router-dom";
import { useEffect } from "react";
import "./portal.css";
import { Dropdown } from "flowbite-react";
import { User } from "../../../interfaces";
import { useState } from "react";

export default function UserIconDropDown({ user }: { user: User }) {
    const navigate = useNavigate();
    const location = useLocation();
    const [isAdmin, setIsAdmin] = useState(user?.is_admin || user?.is_owner);
    const [currentlyInAdminPage, setCurrentlyInAdminPage] = useState(false);

    useEffect(() => {
        setCurrentlyInAdminPage(location.pathname.startsWith("/admin"));
    }, [location.pathname]);

    return (
        <>
            <Dropdown.Item onClick={() => {}} className="font-bold">
                {user?.full_name}
            </Dropdown.Item>
            <Dropdown.Item onClick={() => {}}>{user?.email}</Dropdown.Item>
            {isAdmin && !currentlyInAdminPage && (
                <Dropdown.Item
                    onClick={() => {
                        navigate("/admin/users", { replace: true });
                    }}
                    className="">
                    Admin Console
                </Dropdown.Item>
            )}
            <Dropdown.Divider className="bg-gray-200" />
            <Dropdown.Item
                onClick={() => {
                    sessionStorage.clear();
                    localStorage.clear();
                    navigate("/", { replace: true });
                }}
                className="text-red-500">
                Sign out
            </Dropdown.Item>
        </>
    );
}




