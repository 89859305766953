import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { RouterProvider } from "react-router-dom";
import { ROUTER } from "./components/Router/Router";
import SuperTokens from "supertokens-web-js";
import Session from "supertokens-web-js/recipe/session";
import Passwordless from "supertokens-web-js/recipe/passwordless";
import ThirdPartyPasswordless from "supertokens-web-js/recipe/thirdpartypasswordless";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { UserProvider } from "./context/UserContext";
window.Buffer = window.Buffer || require("buffer").Buffer;

const apiDomain: string =
    window.location.origin !== "http://localhost:3001"
        ? process.env.REACT_APP_API_DOMAIN || "http://localhost:3001"
        : "http://localhost:3001";

// SuperTokens.init({
//   appInfo: {
//     apiDomain: apiDomain,
//     apiBasePath: process.env.REACT_APP_API_BASE_PATH || "/api/auth",
//     appName: process.env.REACT_APP_APP_NAME || "Circle"
//   },
//   recipeList: [
//     Session.init(),
//     Passwordless.init(),
//     ThirdPartyPasswordless.init()
//   ]
// });

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);
root.render(
    <UserProvider>
        <RouterProvider router={ROUTER} />
    </UserProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


