import { createContext, useEffect, useState } from "react";
import {
    mapAllUsers,
    mapLoggedInUser,
} from "../components/Content/AdminConsole/utils/helper";
import { consumeAuthKey } from "../Utilities/consumeAuthKey";
import { getUserInvitations } from "../Utilities/getUserInvitations";
import {
    OneTimeAuthConsumeResponse,
    UserContext as UserContextInterface,
} from "../interfaces";

const defaultProvider = {
    user: {
        result: "",
        msg: "",
        email: "",
        user_id: "",
        avatar_version: 0,
        is_admin: false,
        is_owner: false,
        is_guest: false,
        is_billing_admin: false,
        role: "",
        is_bot: false,
        full_name: "",
        timezone: "",
        is_active: false,
        date_joined: "",
        delivery_email: "",
        avatar_url: "",
        profile_data: {},
        max_message_id: 0,
        workspace_id: "",
    },
    users: [],
    config: () => Promise.resolve(),
    fetchUsers: () => {},
    fetchUserGroups: () => {},
    userGroups: null,
    setUserGroups: () => {},
};

const UserContext = createContext<UserContextInterface>(defaultProvider);

function UserProvider({ children }: any) {
    const [user, setUser] = useState<any>();
    const [users, setUsers] = useState<any>([]);
    const [userGroups, setUserGroups] = useState<any>([]);

    function useQuery() {
        return new URLSearchParams(document.location.search);
    }

    const query = useQuery();

    const setupZulip = async () => {
        // Check if the user is logged in by ensuring the user object is set
        // and the localStorage has all the necessary items
        if (
            user &&
            localStorage.getItem("accessToken") &&
            localStorage.getItem("workspaceId") &&
            localStorage.getItem("workspaceName") &&
            localStorage.getItem("email") &&
            localStorage.getItem("workspaceRole")
        ) {
            return true;
        }

        const oneTimeAuthKey = query.get("oneTimeAuthKey");
        let subdomain = window.location.host.split(".")[0];
        let loggedInUser = null;

        if (oneTimeAuthKey) {
            const data: OneTimeAuthConsumeResponse = await consumeAuthKey(
                oneTimeAuthKey
            );

            if (!data) {
                console.log("no consumeAuthKey data");
                return false;
            } else {
                console.log("consumeAuthKey data: ", data);
                loggedInUser = mapLoggedInUser(data);
                console.log("loggedInUser data: ", loggedInUser);
                setUser(loggedInUser);
            }

            const email = data.email;
            const token = data.token;
            subdomain = data.workspaceSubdomain;
            localStorage.setItem("inviteToken", data.token as string);
            localStorage.setItem("workspaceId", data.workspaceId as string);
            localStorage.setItem("workspaceName", data.workspaceName as string);
            localStorage.setItem("workspaceRole", data.workspaceRole as string);

            localStorage.setItem("email", email as string);
            localStorage.setItem("accessToken", token as string);
            if (loggedInUser?.is_admin || loggedInUser?.is_owner) {
                fetchUsers();
            }
            return true;
        }
        return false;
    };

    const fetchUsers = async () => {
        try {
            const allUsers = await getUserInvitations();
            const users = mapAllUsers(allUsers);
            console.log("🚀 ~ fetchUsers ~ users:", users);

            setUsers(
                users.map((user: any, index: number) => {
                    user.id = index + 1;
                    return user;
                })
            );
        } catch (error) {
            console.log("🚀 ~ fetchUsers ~ error:", error);
        }
    };

    const fetchUserGroups = async (zulipClient: any = null) => {
        if (!zulipClient) return [];
        try {
            const response = await zulipClient.users.groups.retrieve();
            console.log(
                "user group",
                response.user_groups.filter(
                    (group: any) => !group.is_system_group
                )
            );
            setUserGroups(
                response.user_groups.filter(
                    (group: any) => !group.is_system_group
                )
            );
        } catch (error) {
            console.log("🚀 ~ fetchUserGroups ~ error:", error);
        }
    };

    const values = {
        user,
        users,
        config: setupZulip,
        fetchUsers,
        fetchUserGroups,
        userGroups,
        setUserGroups,
    };

    return (
        <UserContext.Provider value={values}>{children}</UserContext.Provider>
    );
}

export { UserContext, UserProvider };










