import { INTERNAL_SERVER_ERROR } from "../Constants/MessageConstants";
import axios from "axios";

const apiDomain = process.env.REACT_APP_API_DOMAIN;

export async function createAuthKey(
    apiKey: string,
    workspaceId: string
): Promise<any> {
    try {
        const result = await axios.post(
            `${apiDomain}/api/v2/auth/oneTimeUseAuthTokens`,
            {
                workspaceId,
            },
            {
                headers: {
                    Authorization: `Bearer ${apiKey}`,
                },
            }
        );
        return result.data;
    } catch (err: any) {
        if (err.isSuperTokensGeneralError) {
            return err.message;
        } else {
            return INTERNAL_SERVER_ERROR;
        }
    }
}


