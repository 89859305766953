import axios from "axios";

const apiDomain = process.env.REACT_APP_API_DOMAIN || "http://localhost:3000";

export const deactivateUser = async (userId: string): Promise<any> => {
  const accessToken = localStorage.getItem("accessToken");
  const zulipToken = `Bearer ${accessToken}`;

  const workspaceId = localStorage.getItem("workspaceId");

  try {
    const url = `${apiDomain}/api/v2/workspaces/${workspaceId}/users/${userId}/deactivate`;

    const response = await axios.patch(
      url,
      {},
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: zulipToken,
        },
      }
    );
    return response;
  } catch (err: any) {
    console.log("Error while reactivating user: ", err.response);
    alert('Server Error. Could not deactivate user!');
  }
};



