import axios from "axios";

export const revokeUserInvitation = async (invitationId: any) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const zulipToken = `Bearer ${accessToken}`;

    const workspaceId = localStorage.getItem("workspaceId");


    const subdomain = window.location.host.split(".")[0];
    const apiDomain: string =
      window.location.origin !== `http://${subdomain}.localhost:3001`
        ? process.env.REACT_APP_API_DOMAIN || "http://localhost:3000"
        : "http://localhost:3000";

    const url = `${apiDomain}/api/v2/workspaces/${workspaceId}/invitations/${invitationId}/revoke`;
    

    const response = await axios.patch(
      url,
      {},
      {
        headers: {
          Authorization: zulipToken,
        },
      }
    );
    return response;
  } catch (err: any) {
    console.log("Error while deleting: ", err.response);
    alert('Server Error. Could not delete user!');
    return err.response.data.message;
  }


};