import { Avatar, Badge, Dropdown, Spinner, Tooltip } from "flowbite-react";
import { FC, useContext, useEffect, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { checkInvitationExpiredOrNot, getRowId } from "./utils/helper";
import {
  ROLE_NAMES,
  STATUS_NAMES,
  STATUS_VALUES,
  defaultColDef,
  ROLE_VALUES,
} from "./utils/constants";
import deleteIcon from "./images/deleteIcon.svg";
import editIcon from "./images/editIcon.svg";
import ConfirmationModal from "../../Reusable/ConfirmationModal";
import AddEditUserModal from "./AddEditUserModal";
import moment from "moment";
import { UserContext } from "../../../context/UserContext";
import SuccessModal from "../../Reusable/SuccessModal";
import { TbMailX, TbMailShare } from "react-icons/tb";
import { resendUserInvitation } from "../../../Utilities/reInviteUser";
import { revokeUserInvitation } from "../../../Utilities/revokeInvitation";
import { HiDotsVertical } from "react-icons/hi";
import AddEditRoleModal from "./AddEditRoleModal";
import { reactivateUser } from "../../../Utilities/reactivateUser";
import { deactivateUser } from "../../../Utilities/deactivateUser";

interface AccountsTableProps {
    tableHeight: number;
    searchText: string;
    users: any[];
    statusValue: any;
    roleValue: any;
}

const AccountsTable: FC<AccountsTableProps> = ({
    tableHeight,
    searchText,
    statusValue,
    roleValue,
}) => {
    const tableRef = useRef<AgGridReact>(null);
    const userContext = useContext(UserContext);
    const [userToDelete, setUserToDelete] = useState<any>();
    const [openDeleteConfirmationDialog, setOpenDeleteConfirmationDialog] =
        useState(false);
    const [
        openReactivateConfirmationDialog,
        setOpenReactivateConfirmationDialog,
    ] = useState(false);
    const [selectedUser, setSelectedUser] = useState<any>();
    const [openEditUserModal, setOpenEditUserModal] = useState(false);
    const [userToReactivate, setUserToReactivate] = useState<any>();
    const [showInviteSentModal, setShowInviteSentModal] = useState(false);
    const [openEditRoleModal, setEditRoleModal] = useState(false);
    const [successModalMessage, setSuccessModalMessage] = useState("Success!");

    const toggleEditUserModal = () => setOpenEditUserModal((old) => !old);

    const toggleEditRoleModal = () => setEditRoleModal((old) => !old);

    const onClickEditUserIcon = (user: any) => {
        setSelectedUser(user);
        toggleEditUserModal();
    };

    const onClickEditUserRole = (user: any) => {
        // For Owner role, we don't allow editing the role
        if (user.role !== ROLE_NAMES.Owner) {
            setSelectedUser(user);
            toggleEditRoleModal();
        }
    };

    const handleRoleChangeSuccess = (response: any) => {
        userContext.fetchUsers();
        tableRef.current?.api.redrawRows({ rowNodes: userContext.users });
        toggleEditRoleModal();
        const fullName = response.data.fullName;
        const role = response.data.workspaceRole;
        setSuccessModalMessage("Role changed successfully");
        setSuccessModalMessage(
            `${fullName}'s role changed successfully to ${role}`
        );
        toggleShowSuccessModal();
    };

    const toggleDeleteConfirmationDialog = () =>
        setOpenDeleteConfirmationDialog((old) => !old);

    const toggleReactivateConfirmationDialog = () => {
        setOpenReactivateConfirmationDialog((old) => !old);
    };
    const toggleShowSuccessModal = () => setShowInviteSentModal((old) => !old);

    const onClickDeleteIcon = (user: any) => {
        setUserToDelete(user);
        toggleDeleteConfirmationDialog();
    };

    const onClickReactivateIcon = (user: any) => {
        setUserToReactivate(user);
        toggleReactivateConfirmationDialog();
    };

    const onCheckboxChange = () => {
        console.log("selected");
    };

    const handleInviteSuccess = (response: any) => {
        userContext.fetchUsers();
        tableRef.current?.api.redrawRows({ rowNodes: userContext.users });
        const fullName = response.data.fullName;
        toggleEditUserModal();
        setSuccessModalMessage(`Name changed successfully to ${fullName}`);
        toggleShowSuccessModal();
    };

    useEffect(() => {
        tableRef?.current?.api?.redrawRows({ rowNodes: userContext.users });
    }, [userContext.users, roleValue, statusValue]);

    const onConfirmDelete = async () => {
        try {
            let response: any;
            if (userToDelete.is_invited) {
                response = await revokeUserInvitation(
                    userToDelete.invited_user_id
                );
            } else {
                response = await deactivateUser(userToDelete.invited_user_id);
            }

            if (response.status === 200) {
                userContext.fetchUsers();
                tableRef.current?.api.redrawRows({
                    rowNodes: userContext.users,
                });

                const successMessage = userToDelete.is_invited
                    ? `Invitation revoked for ${userToDelete.full_name} (${userToDelete.email}).`
                    : `${response.data.fullName} (${response.data.email}) deactivated successfully.`;

                setSuccessModalMessage(successMessage);
                toggleShowSuccessModal();
            }
            toggleDeleteConfirmationDialog();
        } catch (error) {
            console.log("onConfirmDelete error:", error);
            toggleDeleteConfirmationDialog();
        }
    };

    const onConfirmReactivateUser = async () => {
        try {
            let response: any;
            response = await reactivateUser(userToReactivate.invited_user_id);
            if (response.status === 200) {
                userContext.fetchUsers();
                tableRef.current?.api.redrawRows({
                    rowNodes: userContext.users,
                });
                console.log("User reactivated successfully.");
                setSuccessModalMessage(
                    `${response.data.fullName} (${response.data.email}) reactivated successfully.`
                );
                toggleShowSuccessModal();
            }
            toggleReactivateConfirmationDialog();
        } catch (error: any) {
            console.log("onConfirmReactivateUser error:", error);
            toggleReactivateConfirmationDialog();
        }
    };

    const getUserJoiningDate = (params: any) => {
        return moment(params?.data?.date_joined).format("MM-DD-YYYY hh:mm");
    };

    const reinviteUsers = async (invited_user_id: any, email: any) => {
        try {
            let response: any;

            response = await resendUserInvitation(invited_user_id);
            if (response.status === 200) {
                userContext.fetchUsers();
                tableRef.current?.api.redrawRows({
                    rowNodes: userContext.users,
                });

                setSuccessModalMessage(
                    `Invitation resent successfully to ${email}`
                );
                toggleShowSuccessModal();
            }
        } catch (error: any) {
            console.log("reinviteUsers error:", error);
        }
    };

    const getUsers = (roleValue: any, statusValue: any) => {
        let filteredList: any = [],
            selectedRoleFilteredList: any = [];
        if (roleValue && roleValue.length > 0) {
            for (let r = 0; r < roleValue.length; r++) {
                const newList = userContext.users.filter((user: any) => {
                    return user.role === roleValue[r];
                });
                selectedRoleFilteredList = [
                    ...selectedRoleFilteredList,
                    ...newList,
                ];
            }
        } else {
            selectedRoleFilteredList = userContext.users;
        }
        if (statusValue && statusValue.length > 0) {
            for (let s = 0; s < statusValue.length; s++) {
                const newList = selectedRoleFilteredList.filter((user: any) => {
                    if (statusValue[s] === "active" && user.is_active) {
                        return user;
                    }
                    if (statusValue[s] === "invited" && user.is_invited) {
                        return user;
                    }
                    if (statusValue[s] === "deactivate" && !user.is_active) {
                        return user;
                    }
                });
                filteredList = [...filteredList, ...newList];
            }
        } else {
            filteredList = selectedRoleFilteredList;
        }
        if (
            (!roleValue || roleValue.length === 0) &&
            (!statusValue || statusValue.length === 0)
        ) {
            return userContext.users;
        }
        return filteredList.sort((a: any, b: any) => a.id - b.id);
    };

    const columnDefs: any = [
        { field: "id", hide: true },
        {
            headerCheckboxSelection: true,
            checkboxSelection: true,
            width: 50,
            maxWidth: 50,
            sortable: false,
            cellStyle: { border: "unset" },
            pinned: true,
            hide: true,
        },
        {
            field: "full_name",
            headerName: "Full Name",
            cellRenderer: (params: any) => (
                <div className="flex items-center pt-2">
                    <div className="flex flex-row h-full text-sm font-semibold items-center">
                        <div className="mr-2 ">
                            <Avatar
                                alt="User settings"
                                img={params?.data?.avatar_url}
                                rounded
                                size="sm"
                            />
                        </div>
                        <div style={{ maxWidth: 220, overflow: "hidden" }}>
                            {params?.data?.full_name}
                        </div>
                    </div>
                    <div className="ml-auto">
                        <Dropdown
                            arrowIcon={false}
                            inline
                            label={
                                <span>
                                    <HiDotsVertical />
                                </span>
                            }>
                            {!params?.data?.is_invited && (
                                <Dropdown.Item
                                    onClick={() =>
                                        onClickEditUserIcon(params?.data)
                                    }>
                                    Edit User Info
                                </Dropdown.Item>
                            )}
                            {!params?.data?.is_invited &&
                                params?.data?.role !== ROLE_NAMES.Owner && (
                                    <Dropdown.Item
                                        onClick={() =>
                                            onClickEditUserRole(params?.data)
                                        }>
                                        Edit User Role
                                    </Dropdown.Item>
                                )}
                            {params?.data?.is_invited &&
                                checkInvitationExpiredOrNot(
                                    params?.data?.expiry_date * 1000
                                ) && (
                                    <Dropdown.Item
                                        onClick={async () =>
                                            await reinviteUsers(
                                                params?.data?.invited_user_id,
                                                params?.data?.email
                                            )
                                        }>
                                        Resend Invite
                                    </Dropdown.Item>
                                )}
                            {params?.data?.is_invited && (
                                <Dropdown.Item
                                    onClick={() =>
                                        onClickDeleteIcon(params?.data)
                                    }>
                                    Revoke Invite
                                </Dropdown.Item>
                            )}

                            {params?.data?.is_active &&
                                !params?.data?.is_owner && (
                                    <Dropdown.Item
                                        onClick={() =>
                                            onClickDeleteIcon(params?.data)
                                        }>
                                        Deactivate User
                                    </Dropdown.Item>
                                )}
                            {!params?.data?.is_active &&
                                !params?.data?.is_owner &&
                                !params?.data?.is_invited && (
                                    <Dropdown.Item
                                        onClick={() =>
                                            onClickReactivateIcon(params?.data)
                                        }>
                                        Reactivate User
                                    </Dropdown.Item>
                                )}
                        </Dropdown>
                    </div>
                </div>
            ),
            minWidth: 350,
            cellStyle: { border: "unset", position: "static" },
            unSortIcon: true,
            pinned: true,
        },
        {
            field: "email",
            cellRenderer: (params: any) => (
                <div className="mt-4 text-sm text-gray-500">
                    {params?.data?.email}
                </div>
            ),
            minWidth: 80,
            cellStyle: { border: "unset" },
            unSortIcon: true,
        },
        {
            field: "role",
            cellRenderer: (params: any) => {
                const role =
                    ROLE_NAMES[params?.data?.role as keyof typeof ROLE_NAMES];
                let textStyle = "text- text-gray-500 ";

                if (role === ROLE_NAMES.Owner) {
                    textStyle = "font-bold text-primary-500";
                    return (
                        <Badge
                            color="indigo"
                            className="text-gray-800 font-bold">
                            {role}
                        </Badge>
                    );
                } else if (role === "Admin") {
                    textStyle = "font-semibold text-gray-600";
                    return (
                        <Badge className="text-gray-600 bg-primary-300/20">
                            {role}
                        </Badge>
                    );
                } else {
                    // return <div className={`mt-4 text-sm font- ${textStyle}`}>{role}</div>;
                    return <Badge className="text-gray-600">{role}</Badge>;
                }
            },
            minWidth: 80,
            maxWidth: 120,
            cellStyle: { border: "unset" },
            unSortIcon: true,
        },
        {
            field: "status",
            cellRenderer: (params: any) => {
                checkInvitationExpiredOrNot(params?.data?.expiry_date * 1000);
                let statusVal: string;
                let statusBadgeColor: string;

                if (params?.data?.is_active) {
                    statusVal = STATUS_NAMES.active;
                    statusBadgeColor = "success";
                } else if (params?.data?.is_invited) {
                    statusVal = STATUS_NAMES.invited;
                    statusBadgeColor = "warning";
                } else {
                    statusVal = STATUS_NAMES.deactivated;
                    statusBadgeColor = "gray";
                }
                return (
                    <div className="w-full h-full flex items-center gap-2">
                        <Badge
                            color={statusBadgeColor}
                            className="text-gray-500">
                            {statusVal}
                        </Badge>
                    </div>
                );
            },
            maxWidth: 120,
            cellStyle: { border: "unset" },
            unSortIcon: true,
        },
        {
            field: "date Joined / Invited",
            cellRenderer: (params: any) => (
                <div className="mt-4 text-sm text-gray-500">
                    {getUserJoiningDate(params)}
                </div>
            ),
            minWidth: 80,
            cellStyle: { border: "unset" },
            unSortIcon: true,
        },
    ];

    const noRowsComponent = () => {
        return (
            <div className="ag-overlay-no-rows-wraper">
                <div className="text-2xl text-gray-400">
                    Nothing to display!
                </div>
            </div>
        );
    };

    return (
        <div className="ag-theme-alpine" style={{ height: tableHeight - 12 }}>
            <AgGridReact
                ref={tableRef}
                rowData={getUsers(roleValue, statusValue)}
                getRowId={getRowId}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                onSelectionChanged={onCheckboxChange}
                noRowsOverlayComponent={noRowsComponent}
                gridOptions={{
                    rowSelection: "multiple",
                    suppressRowClickSelection: true,
                    suppressContextMenu: true,
                }}
                quickFilterText={searchText}
                getRowStyle={() => ({
                    background: "transparent",
                    transform: "none",
                    position: "relative",
                })}
                rowHeight={50}
                pagination={true}
                paginationPageSizeSelector={[10, 20, 50, 100]}
                paginationPageSize={10}
            />
            <ConfirmationModal
                open={openDeleteConfirmationDialog}
                toggleModal={toggleDeleteConfirmationDialog}
                text={`Are you sure you want to ${
                    userToDelete?.is_invited ? "revoke" : "deactivate"
                } the user ?`}
                highlightedText={`${userToDelete?.full_name} (${userToDelete?.email})`}
                confirmAction={onConfirmDelete}
                confirmButtonText={
                    userToDelete?.is_invited ? "Revoke" : "Deactivate"
                }
            />
            <ConfirmationModal
                open={openReactivateConfirmationDialog}
                toggleModal={toggleReactivateConfirmationDialog}
                text={`Are you sure you want to reactivate the user ?`}
                highlightedText={`${userToReactivate?.full_name} (${userToReactivate?.email})`}
                confirmAction={onConfirmReactivateUser}
                confirmButtonText={"Rectivate"}
            />
            <AddEditUserModal
                open={openEditUserModal}
                toggleModal={toggleEditUserModal}
                selectedUser={selectedUser}
                existingUserEmails={[]}
                onInviteSuccess={handleInviteSuccess}
            />
            <AddEditRoleModal
                open={openEditRoleModal}
                toggleModal={toggleEditRoleModal}
                selectedUser={selectedUser}
                onRoleChangeSuccess={handleRoleChangeSuccess}
            />

            {
                <SuccessModal
                    open={showInviteSentModal}
                    toggleModal={toggleShowSuccessModal}
                    text={successModalMessage}
                />
            }
        </div>
    );
};

export default AccountsTable;




