import axios from "axios";

export const editUser = async (user: any, updatedName: string | null, updatedRole: string | null) => {
    try {
        const accessToken = localStorage.getItem("accessToken");
        const zulipToken = `Bearer ${accessToken}`;

        const trimmedName = updatedName?.trim() || null;
        const trimmedRole = updatedRole?.trim() || null;

        const workspaceId = localStorage.getItem("workspaceId");

        const subdomain = window.location.host.split(".")[0];
        const apiDomain: string =
            window.location.origin !== `http://${subdomain}.localhost:3001`
                ? process.env.REACT_APP_API_DOMAIN || "http://localhost:3000"
                : "http://localhost:3000";

        const url = `${apiDomain}/api/v2/workspaces/${workspaceId}/users/${user.invited_user_id}/`;
        console.log('url: ' + url);
        const data = {
            fullName: trimmedName,
            role: trimmedRole,
        };

        const response = await axios.put(
            url,
            data,
            {
                headers: {
                    Authorization: zulipToken,
                },
            }
        );

        return response;
    } catch (err: any) {
        console.log("Error while editing user data: ", err.response);
        alert('Server Error. Could not update user data!');
        throw err;
    }
};


