import { Button, Label, Modal, TextInput } from "flowbite-react";
import { FC, useContext, useState } from "react";
import { UserContext } from "../../../context/UserContext";
import { validateEmail } from "./utils/helper";
import RightDrawer from "../../Reusable/RightDrawer";

interface AddUserMembersProps {
    open: boolean;
    toggleModal: () => void;
    toggleParentModal: () => void;
    name?: string;
    description?: string;
    selectedUserGroup?: any;
}

const AddUserMembers: FC<AddUserMembersProps> = ({
    open,
    toggleModal,
    toggleParentModal,
    name,
    description,
    selectedUserGroup,
}) => {
    console.log("🚀 ~ selectedUserGroup:", selectedUserGroup);
    const userContext = useContext(UserContext);
    const membersIdList = selectedUserGroup?.members;

    const getExistingMembers = () => {
        if (!membersIdList || membersIdList.length === 0) {
            return [];
        }
        for (let i = 0; i < membersIdList.length; i++) {
            return userContext.users.filter((user: any) => {
                if (user.user_id && user.user_id === membersIdList[i]) {
                    return user.email;
                }
            });
        }
    };
    const [emails, setEmails] = useState("");
    const [emailDoesntExists, setEmailDoesntExists] = useState<any>([]);
    const [invalidEmails, setInvalidEmails] = useState<any>([]);

    const existingUserEmails = userContext.users.map((user: any) => user.email);
    let userIds: any = [];

    const checkIfUserAvailableOrNot = () => {
        setEmailDoesntExists([]);
        const notAvailableEmails = [],
            idsNeedToBeAdded: any = [],
            invalidEmailList: any = [];
        const listOfEmails = emails
            .split(",")
            .map((email: any) => email.trim());
        for (let i = 0; i < listOfEmails.length; i++) {
            if (listOfEmails[i] === "") return true;
            if (!validateEmail(listOfEmails[i])) {
                invalidEmailList.push(listOfEmails[i]);
                continue;
            }
            if (!existingUserEmails.includes(listOfEmails[i])) {
                notAvailableEmails.push(listOfEmails[i]);
            } else {
                userContext.users.filter((user: any) => {
                    if (user.email === listOfEmails[i]) {
                        idsNeedToBeAdded.push(user.user_id);
                    }
                });
            }
        }
        if (notAvailableEmails.length > 0) {
            setEmailDoesntExists(notAvailableEmails);
            return false;
        } else if (invalidEmailList.length > 0) {
            setInvalidEmails(invalidEmailList);
            return false;
        } else {
            setInvalidEmails([]);
            userIds = idsNeedToBeAdded;
            return true;
        }
    };

    const onSubmitForm = async (e: any) => {
        e.preventDefault();
        try {
            if (checkIfUserAvailableOrNot()) {
                if (selectedUserGroup) {
                    const idsNeedToBeAdded: any = [];
                    const existingUserIds = selectedUserGroup.members;
                    for (let i = 0; i < userIds.length; i++) {
                        if (!existingUserIds.includes(userIds[i])) {
                            idsNeedToBeAdded.push(userIds[i]);
                        }
                    }
                    throw new Error("Not implemented");
                    const response =
                        // @ts-ignore
                        await userContext.client.users.groups.members.update({
                            id: selectedUserGroup.id,
                            add: idsNeedToBeAdded,
                        });
                    if (response.result === "error") {
                        alert(response.msg);
                    } else {
                        userContext.fetchUserGroups();
                        toggleModal();
                    }
                } else {
                    throw new Error("Not implemented");
                    // @ts-ignore
                    const response =
                        // @ts-ignore
                        await userContext.client.users.groups.create({
                            name: name,
                            description: description,
                            members: userIds,
                        });
                    if (response.result === "error") {
                        alert(response.msg);
                    } else {
                        userContext.fetchUserGroups();
                        toggleModal();
                        toggleParentModal();
                    }
                }
            }
        } catch (error) {
            console.log("🚀 ~ onSubmitForm ~ error:", error);
        }
        console.log("submit");
    };

    const isSubmitDisabled = !emails.trim();

    return (
        <RightDrawer open={open} handleOpen={toggleModal}>
            <div className="space-y-2">
                <h3 className="text-xl font-medium text-gray-900 dark:text-white">
                    {selectedUserGroup
                        ? `Members ${selectedUserGroup.members.length}`
                        : "Add Members"}
                </h3>
                <form onSubmit={onSubmitForm}>
                    <div className="mt-5">
                        <div className="mb-2 block">
                            <Label htmlFor="emails" value="Name:" />
                        </div>
                        <TextInput
                            id="emails"
                            value={emails}
                            onChange={(event) => setEmails(event.target.value)}
                            required={!selectedUserGroup}
                        />
                        {invalidEmails.length > 0 && (
                            <div>
                                <text className="text-xs text-red-500">
                                    Invalid Emails: {invalidEmails.toString()}
                                </text>
                            </div>
                        )}
                    </div>
                    {emailDoesntExists && emailDoesntExists.length > 0 && (
                        <div>
                            <text className="text-xs text-red-500">{`Emails are not exists in this realm: ${emailDoesntExists}`}</text>
                        </div>
                    )}
                    <div className="flex items-center justify-between mt-10">
                        <text className="text-xs text-gray-500">
                            Step 2 of 2
                        </text>
                        <div className="w-[100px]">
                            <Button
                                type="submit"
                                className="bg-indigo-700 w-full"
                                color="primary"
                                disabled={isSubmitDisabled}>
                                {"Save"}
                            </Button>
                        </div>
                    </div>
                </form>
            </div>
        </RightDrawer>
    );
};

export default AddUserMembers;





