import { Button, Dropdown, TextInput } from "flowbite-react";
import { FC, useContext } from "react";
import { HiSearch } from "react-icons/hi";
import arrowDownIcon from "./images/downIcon.svg";
import { USERGROUPS_STATUS_OPTIONS } from "./utils/constants";
import { UserContext } from "../../../context/UserContext";

interface UserGroupTableOptionsProps {
    searchText: string;
    setSearchText: (text: string) => void;
    userGroupsCount: number;
    onClickAddUserGroup: () => void;
}

const dropdownOptions = [
    {
        title: "Status",
        list: USERGROUPS_STATUS_OPTIONS,
    },
];

const UserGroupTableOptions: FC<UserGroupTableOptionsProps> = ({
    searchText,
    setSearchText,
    userGroupsCount,
    onClickAddUserGroup,
}) => {
    const userContext = useContext(UserContext);

    const totalMembersCount = () => {
        let count = 0;
        for (let i = 0; i < userContext.userGroups.length; i++) {
            count += userContext.userGroups[i].members.length;
        }
        return count;
    };

    return (
        <>
            <div className=" flex items-center w-full">
                <div className="text-3xl font-bold">Groups</div>
                <div className="ml-auto">
                    <Button
                        className="w-[130px] h-[34px] bg-indigo-700"
                        onClick={onClickAddUserGroup}>
                        Create Group
                    </Button>
                </div>
            </div>
            <div className="flex items-center w-full mt-4 bg-gray-100 py-3 px-2 mb-2">
                <div className="flex flex-row items-center justify-between w-full">
                    <div className="text-14">
                        Members - {totalMembersCount()}
                    </div>
                    <div className="flex flex-row gap-2">
                        <TextInput
                            icon={HiSearch}
                            id="search"
                            name="search"
                            placeholder="Search"
                            size={32}
                            type="search"
                            style={{ height: "37px" }}
                            className="focus:border-indigo-700"
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                        />
                    </div>
                </div>
            </div>
            {/* <>
            <div className="ml-6">
              <TextInput
                icon={HiSearch}
                id="search"
                name="search"
                placeholder="Search"
                size={32}
                type="search"
                style={{ height: "37px" }}
                className="focus:border-indigo-700"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />
            </div>
            {dropdownOptions.map((item) => {
              return (
                <div className="ml-10">
                  <Dropdown
                    arrowIcon={false}
                    inline
                    label={
                      <div className="flex py-2 px-3 justify-between items-center border border-gray-300 rounded-md text-gray-500 text-sm h-[37px] w-[140px] gap-4 bg-gray-50">
                        {item.title}
                        <img src={arrowDownIcon} alt="arrow icon" />
                      </div>
                    }
                  >
                    {item.list.map((option) => (
                      <Dropdown.Item key={option.id}>{option.label}</Dropdown.Item>
                    ))}
                  </Dropdown>
                </div>
              )
            })}
          </> */}
        </>
    );
};

export default UserGroupTableOptions;



