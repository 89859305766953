import { Dropdown, Button, Tooltip } from "flowbite-react";
import { FC, useContext, useEffect, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { getRowId } from "./utils/helper";
import { defaultColDef } from "./utils/constants";
import deleteIcon from "./images/deleteIcon.svg";
import ConfirmationModal from "../../Reusable/ConfirmationModal";
import { UserContext } from "../../../context/UserContext";
import AddEditUserGroupModal from "./AddEditUserGroupModal";
import EditUserMembers from "./EditUserMembers";
import { PiDotsThreeCircleVertical } from "react-icons/pi";
import { HiDotsVertical } from "react-icons/hi";

interface AccountsTableProps {
    tableHeight: number;
    searchText: string;
}

const UserGroups: FC<AccountsTableProps> = ({ tableHeight, searchText }) => {
    const tableRef = useRef<AgGridReact>(null);
    const userContext = useContext(UserContext);
    const [userGroupToDelete, setUserGroupToDelete] = useState<any>();
    const [openDeleteConfirmationDialog, setOpenDeleteConfirmationDialog] =
        useState(false);
    const [selectedUserGroup, setSelectedUserGroup] = useState<any>();
    const [openEditUserGroupModal, setOpenEditUserGroupModal] = useState(false);
    const [openEditMembersModal, setOpenEditMembersModal] = useState(false);

    const toggleEditUserGroupModal = () =>
        setOpenEditUserGroupModal((old) => !old);
    const toggleEditMembersModal = () => setOpenEditMembersModal((old) => !old);

    const onClickEditUserGroup = (user: any) => {
        setSelectedUserGroup(user);
        toggleEditUserGroupModal();
    };

    const onClickEditMembers = (user: any) => {
        setSelectedUserGroup(user);
        toggleEditMembersModal();
    };

    const toggleDeleteConfirmationDialog = () =>
        setOpenDeleteConfirmationDialog((old) => !old);

    const onClickDeleteIcon = (user: any) => {
        setUserGroupToDelete(user);
        toggleDeleteConfirmationDialog();
    };

    const onCheckboxChange = () => {
        console.log("selected");
    };

    useEffect(() => {
        tableRef?.current?.api?.redrawRows({
            rowNodes: userContext.userGroups,
        });
    }, []);

    const onConfirmDelete = async () => {
        try {
            throw new Error("Not implemented");
            // @ts-ignore
            const response = await userContext.client.users.groups.delete({
                id: userGroupToDelete.id,
            });
            if (response.result === "error") {
                alert(response.msg);
            } else {
                userContext.fetchUserGroups();
                toggleDeleteConfirmationDialog();
            }
        } catch (error) {
            console.log("🚀 ~ onConfirmDelete ~ error:", error);
            alert(error);
            toggleDeleteConfirmationDialog();
        }
        console.log("deleted");
    };

    const columnDefs = [
        { field: "id", hide: true },
        {
            headerCheckboxSelection: true,
            checkboxSelection: true,
            width: 50,
            maxWidth: 50,
            resizeable: false,
            sortable: false,
            cellStyle: () => {
                return { border: "unset", marginTop: "6px" };
            },
            pinned: true,
        },
        {
            field: "name",
            cellRenderer: (params: any) => (
                <div className="flex items-center pt-4">
                    <div className="flex flex-row h-full text-sm font-semibold items-center">
                        <div style={{ maxWidth: 220, overflow: "hidden" }}>
                            {params?.data?.name}
                        </div>
                    </div>
                    <div className="ml-auto">
                        <Dropdown
                            arrowIcon={false}
                            inline
                            label={
                                <span>
                                    <HiDotsVertical />
                                </span>
                            }>
                            <Dropdown.Item
                                onClick={() =>
                                    onClickEditUserGroup(params?.data)
                                }>
                                Edit User Group
                            </Dropdown.Item>
                            <Dropdown.Item
                                onClick={() =>
                                    onClickEditMembers(params?.data)
                                }>
                                Edit Members
                            </Dropdown.Item>
                            <Dropdown.Item
                                onClick={() => onClickDeleteIcon(params?.data)}>
                                Delete Group
                            </Dropdown.Item>
                        </Dropdown>
                    </div>
                </div>
            ),
            unSortIcon: true,
            pinned: true,
            minWidth: 300,
            cellStyle: () => {
                return { border: "unset", overflow: "unset" };
            },
        },
        {
            field: "description",
            cellRenderer: (params: any) => (
                <div className="mt-4 text-sm text-gray-500">
                    {params?.data?.description}
                </div>
            ),
            cellStyle: { border: "unset" },
            unSortIcon: true,
        },
        {
            field: "members",
            cellRenderer: (params: any) => (
                <div className="mt-4 text-sm text-gray-500 flex flex-row justify-between">
                    <p>{params?.data?.members.length}</p>
                </div>
            ),
            cellStyle: { border: "unset" },
            unSortIcon: true,
            maxWidth: 150,
        },
    ];

    const noRowsComponent = () => {
        return (
            <div className="ag-overlay-no-rows-wraper">
                <div>No Records to Display</div>
            </div>
        );
    };

    return (
        <div className="ag-theme-alpine" style={{ height: tableHeight - 12 }}>
            <AgGridReact
                ref={tableRef}
                rowData={userContext.userGroups}
                getRowId={getRowId}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                onSelectionChanged={onCheckboxChange}
                noRowsOverlayComponent={noRowsComponent}
                gridOptions={{
                    rowSelection: "multiple",
                    suppressRowClickSelection: true,
                    suppressContextMenu: true,
                }}
                quickFilterText={searchText}
                getRowStyle={() => ({
                    background: "transparent",
                    transform: "none",
                    position: "relative",
                })}
                rowHeight={50}
                pagination={true}
                paginationPageSizeSelector={[10, 20, 50, 100]}
                paginationPageSize={10}
            />

            <ConfirmationModal
                open={openDeleteConfirmationDialog}
                toggleModal={toggleDeleteConfirmationDialog}
                text="Are you sure you want to delete the user"
                highlightedText={userGroupToDelete?.name}
                confirmAction={onConfirmDelete}
            />
            <AddEditUserGroupModal
                open={openEditUserGroupModal}
                toggleModal={toggleEditUserGroupModal}
                selectedUserGroup={selectedUserGroup}
            />
            {openEditMembersModal && (
                <EditUserMembers
                    open={openEditMembersModal}
                    toggleModal={toggleEditMembersModal}
                    toggleParentModal={() => {}}
                    name={selectedUserGroup?.name}
                    description={selectedUserGroup?.description}
                    selectedUserGroup={selectedUserGroup}
                />
            )}
        </div>
    );
};

export default UserGroups;





