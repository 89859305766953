"use client";

import { Button, Toast } from "flowbite-react";
import { useEffect, useState } from "react";
import { createOrganization } from "../../../Utilities/createOrganization";
import CircleLogo from "./CircleLogo";
import { fetchApiKey } from "../../../Utilities/fetchApiKey";
// @ts-ignore
import sha1 from "sha1";
import { useLocation, useNavigate } from "react-router-dom";
import { HiExclamation } from "react-icons/hi";
import { createAuthKey } from "../../../Utilities/createAuthKey";

export default function CreateOrganization() {
  const location = useLocation();
  const navigate = useNavigate();
  const existingOrganizations = location.state.organizations;
  const [organizationName, setOrganizationName] = useState("");
  const [organizationType, setOrganizationType] = useState("");
  const [organizationURL, setOrganizationURL] = useState("");
  const [fullName, setFullName] = useState("");
  const [shouldEnableButton, setShouldEnableButton] = useState(false);
  const [isNameDuplicate, setIsNameDuplicate] = useState(false);
  const [isSubdomainDuplicate, setIsSubdomainDuplicate] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const checkIfAllValuesAreValid = () => {
    if (organizationName !== "" && organizationURL !== "" && fullName !== "") {
      return setShouldEnableButton(true);
    } else {
      return setShouldEnableButton(false);
    }
  };

  const renderInputTextField = (fieldName: string, setValue: any) => {
    return (
      <div className="flex flex-col p-2">
        <text className="font-bold text-m mb-2">{fieldName}</text>
        <input
          type="text"
          required
          name="organizationName"
          className="border-gray-400 rounded"
          onChange={(event) => {
            setValue(event.target.value);
          }}
        />
        {fieldName === "Organization Name" && (
          <text className="font-normal text-sm text-gray-500">
            Shorter is better than longer.
          </text>
        )}
        {fieldName === "Organization Name" && isNameDuplicate && (
          <text className="font-normal text-sm text-red-500">
            This name is already available.
          </text>
        )}
        {fieldName === "Organization URL" && isSubdomainDuplicate && (
          <text className="font-normal text-sm text-red-500">
            This URL is already available.
          </text>
        )}
      </div>
    );
  };

  useEffect(() => {
    checkIfAllValuesAreValid();
  }, [organizationName, organizationURL, fullName]);

  const checkIfOrganizationExists = (
    organizationName: string,
    organizationURL: string
  ) => {
    const checkOrganizationName = existingOrganizations.find((org: any) => {
      return org.name === organizationName;
    });
    const checkOrganizationSubdomain = existingOrganizations.find(
      (org: any) => {
        return org.subdomain === organizationURL;
      }
    );
    setIsNameDuplicate(checkOrganizationName ? true : false);
    setIsSubdomainDuplicate(checkOrganizationSubdomain ? true : false);
    if (checkOrganizationName || checkOrganizationSubdomain) {
      return true;
    } else {
      return false;
    }
  };

  

  const onSubmit = async () => {
      const data = {
          realm_name: organizationName,
          realm_subdomain: organizationURL,
          full_name: fullName,
      };

      if (checkIfOrganizationExists(organizationName, organizationURL)) {
          return;
      }

      const responseData = await createOrganization(data);

      if (responseData.status !== 201) {
          setErrorMessage(responseData.data.msg);
          setTimeout(() => {
              setErrorMessage("");
          }, 5000);
          return;
      }

      const email = localStorage.getItem("email");
      const password = sha1(email as string);

      const response = await fetchApiKey(email, password, organizationURL);
      const zulipKey = response.api_key;

      // TODO: the workspaceId may not be returned in the response
      const authKey = await createAuthKey(zulipKey, response.workspace_id);

      const realmUrl = `${process.env.REACT_APP_HOST_PROTOCOL}://${organizationURL}.${process.env.REACT_APP_HOSTNAME}/portal?oneTimeAuthKey=${authKey.oneTimeAuthKey}`;

      navigate(`/selectOrganization`);

      window.open(realmUrl);
  };
  return (
    <div className="flex flex-col items-center justify-center">
      
      {errorMessage && (
        <Toast>
          <div className="inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg bg-orange-100 text-orange-500 dark:bg-orange-700 dark:text-orange-200">
            <HiExclamation className="h-5 w-5" />
          </div>
          <div className="ml-3 text-sm font-normal">{errorMessage}</div>
          <Toast.Toggle onDismiss={() => setErrorMessage("")} />
        </Toast>
      )}
      <div className="flex flex-row items-center mt-6">
        <CircleLogo height={60} width={60} />
        <text className="font-bold text-3xl">Circle App</text>
      </div>
      <div className="flex flex-col h-full w-[25rem] p-2 min-h-screen">
        <div className="flex flex-col border rounded-lg shadow border-gray-300 p-2 mt-6 w-full pl-4 pr-4">
          {renderInputTextField("Organization Name", setOrganizationName)}
          {renderInputTextField("Organization URL", setOrganizationURL)}
          {renderInputTextField("Full Name", setFullName)}
          <div className="flex flex-col p-2 mt-4 mb-4 w-full">
            {!shouldEnableButton && (
              <Button className="bg-gray-300  enabled:hover:cursor-not-allowed enabled:hover:bg-gray-400">
                Create Organization
              </Button>
            )}
            {shouldEnableButton && (
              <Button className="bg-gray-700 text-white" onClick={onSubmit}>
                Create Organization
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

