import React, { FC, useContext, useEffect, useState } from "react";
import { HiSearch, HiAdjustments } from "react-icons/hi";
import { LuRefreshCcw } from "react-icons/lu";
import { Button, TextInput, Dropdown, Tooltip } from "flowbite-react";
import { ROLE_NAMES, STATUS_OPTIONS } from "./utils/constants";
import { HiOutlinePlus } from "react-icons/hi";
import { UserContext } from "../../../context/UserContext";

interface TableOptionsProps {
    searchText: string;
    setSearchText: (text: string) => void;
    usersCount: number;
    onClickAddUser: () => void;
    setRoleValue: (role: any) => void;
    setStatusValue: (status: any) => void;
    roleValue: any;
    statusValue: any;
}

const TableOptions: FC<TableOptionsProps> = ({
    searchText,
    setSearchText,
    usersCount,
    onClickAddUser,
    setRoleValue,
    setStatusValue,
    roleValue,
    statusValue,
}) => {
    let roles = roleValue;
    const userContext = useContext(UserContext);

    const getRoleFilter = () => {
        return (
            <>
                <div className="mb-2 font-semibold">Filter by Role:</div>
                <div className="flex flex-col gap-2">
                    {Object.entries(ROLE_NAMES).map(([key, name]) => (
                        <label className="inline-flex items-center" key={key}>
                            <input
                                type="checkbox"
                                className="form-checkbox h-5 w-5 text-indigo-600"
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        setRoleValue([...roles, name]);
                                    } else {
                                        roles = roles.filter(
                                            (item: any) => item !== name
                                        );
                                        setRoleValue(roles);
                                    }
                                }}
                                checked={roleValue.includes(name)}
                            />
                            <span className="ml-4">{name}</span>
                        </label>
                    ))}
                </div>
            </>
        );
    };

    const getStatusFilter = () => {
        return (
            <>
                <div className="mb-2 font-semibold">Filter by Status:</div>
                <div className="flex flex-col gap-2">
                    <label className="inline-flex items-center">
                        <input
                            type="checkbox"
                            className="form-checkbox h-5 w-5 text-indigo-600"
                            onChange={(e) => {
                                if (e.target.checked) {
                                    setStatusValue([...statusValue, "active"]);
                                } else {
                                    statusValue = statusValue.filter(
                                        (item: any) => item !== "active"
                                    );
                                    setStatusValue(statusValue);
                                }
                            }}
                            checked={
                                statusValue.filter(
                                    (status: any) => status === "active"
                                ).length > 0
                            }
                        />
                        <span className="ml-4">Active</span>
                    </label>
                    <label className="inline-flex items-center">
                        <input
                            type="checkbox"
                            className="form-checkbox h-5 w-5 text-indigo-600"
                            onChange={(e) => {
                                if (e.target.checked) {
                                    setStatusValue([...statusValue, "invited"]);
                                } else {
                                    statusValue = statusValue.filter(
                                        (item: any) => item !== "invited"
                                    );
                                    setStatusValue(statusValue);
                                }
                            }}
                            checked={
                                statusValue.filter(
                                    (status: any) => status === "invited"
                                ).length > 0
                            }
                        />
                        <span className="ml-4">Invited</span>
                    </label>
                    <label className="inline-flex items-center">
                        <input
                            type="checkbox"
                            className="form-checkbox h-5 w-5 text-indigo-600"
                            onChange={(e) => {
                                if (e.target.checked) {
                                    setStatusValue([
                                        ...statusValue,
                                        "deactivate",
                                    ]);
                                } else {
                                    statusValue = statusValue.filter(
                                        (item: any) => item !== "deactivate"
                                    );
                                    setStatusValue(statusValue);
                                }
                            }}
                            checked={
                                statusValue.filter(
                                    (status: any) => status === "deactivate"
                                ).length > 0
                            }
                        />
                        <span className="ml-4">Deactive</span>
                    </label>
                </div>
            </>
        );
    };

    useEffect(() => {
        getRoleFilter();
    }, [setRoleValue]);

    return (
        <>
            <div className="flex items-center w-full mb-4">
                <div className="text-2xl text-primary-500 font-bold">Users</div>
                <div className="ml-auto">
                    <Button
                        size="sm"
                        className="bg-primary-500 hover:bg-primary-700"
                        onClick={onClickAddUser}>
                        <HiOutlinePlus className="mr-1 h-5 w-5" />
                        Invite Users
                    </Button>
                </div>
            </div>
            {usersCount > 0 && (
                <div className="flex items-center w-full bg-primary-100/50 rounded p-2 mb-2 shadow shadow-gray-300">
                    <div className="flex flex-row items-center justify-between w-full">
                        <div className="flex flex-row gap-2 items-center">
                            <p className="text-xs font-semibold text-primary-500 px-2 py-1 bg-primary-100 rounded-lg">
                                Total {usersCount} Users
                            </p>
                            <Tooltip content="Refresh">
                                <LuRefreshCcw
                                    className="cursor-pointer text-primary-500"
                                    onClick={userContext.fetchUsers}
                                />
                            </Tooltip>
                        </div>
                        <div className="flex flex-row gap-2">
                            <Dropdown
                                arrowIcon={false}
                                inline
                                placement="bottom"
                                label={
                                    <span className="rounded-lg p-2 hover:bg-gray-100 dark:hover:bg-gray-700">
                                        <span className="sr-only">Filter</span>
                                        <Tooltip content="Filter">
                                            <HiAdjustments />
                                        </Tooltip>
                                    </span>
                                }
                                className="w-[200px]">
                                <div className="px-4 py-2">
                                    {getRoleFilter()}
                                    <hr className="my-2"></hr>
                                    {getStatusFilter()}
                                </div>
                            </Dropdown>
                            <TextInput
                                icon={HiSearch}
                                id="search"
                                name="search"
                                placeholder="Search"
                                size={32}
                                type="search"
                                style={{ height: "37px" }}
                                className="focus:border-indigo-700"
                                value={searchText}
                                onChange={(e) => setSearchText(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default TableOptions;

