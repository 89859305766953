import axios from "axios";

const subdomain = window.location.host.split(".")[0];
const apiDomain: string =
  window.location.origin !== `http://${subdomain}.localhost:3001`
    ? process.env.REACT_APP_API_DOMAIN || "http://localhost:3000"
    : "http://localhost:3000";

export async function resendUserInvitation(invitedUserId: any): Promise<any> {
  const accessToken = localStorage.getItem("accessToken");
  const zulipToken = `Bearer ${accessToken}`;


  const workspaceId = localStorage.getItem("workspaceId");
  

  try {
    const url = `${apiDomain}/api/v2/workspaces/${workspaceId}/invitations/${invitedUserId}/resend`;

    const response = await axios.post(
      url,
      {},
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: zulipToken,
        },
      }
    );
    return response;
  } catch (err: any) {
    console.log("Error while resending user invitation: ", err.response);
    alert('Server Error. Could not resend invitation!');
  }
}


